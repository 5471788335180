import { SET_NOTIFICATION_LIST } from "../Actions/actionTypes";

const initialState = {
  list: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_LIST:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
