import { SET_SUBSCRIPTIONS_LIST } from "../Actions/actionTypes";

const initialState = {
  subscriptionsList: []
};

const miscellaneous = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS_LIST:
      return {
        ...state,
        subscriptionsList: action.payload
      };
    default:
      return state;
  }
}

export default miscellaneous;
