import { EMAIL_MODAL_OPEN, EMAIL_MODAL_INFO } from "../Actions/actionTypes";

const initialState = {};

const sendMailModal = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_MODAL_OPEN:
      return {
        ...state,
        open: action.payload,
      };
    case EMAIL_MODAL_INFO:
      return {
        ...state,
        modalInfo: action.payload,
      };
    default:
      return state;
  }
}

export default sendMailModal;
