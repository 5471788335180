import { combineReducers } from 'redux';
import qAnswers from './questionAnswers';
import partnerCode from './partnerCode';
import sendMailModal from './sendMailModal';
import userReducer from './user.reducer';
import notificationReducer from './notification.reducer';
import resultsReducer from './results.reducer';
import miscellaneous from './miscellaneous';
import modalsReducer from './modals.reducer';

const superReducer = combineReducers({
  qAnswers,
  partnerCode,
  sendMailModal,
  userReducer,
  notificationReducer,
  resultsReducer,
  miscellaneous,
  modalsReducer,
});

export default superReducer;
