export const QA_UPDATE_CODE = "QA_UPDATE_CODE";
export const QA_RESET = "QA_RESET";

export const PARTNER_CODE = "PARTNER_CODE";
export const EMAIL_MODAL_OPEN = "EMAIL_MODAL_OPEN";
export const EMAIL_MODAL_INFO = "EMAIL_MODAL_INFO";
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST';
export const SET_CRS_POINTS_FETCH_TIME = 'SET_CRS_POINTS_FETCH_TIME';
export const SET_CRS_POINTS = 'SET_CRS_POINTS';
export const SET_SUBSCRIPTIONS_LIST = 'SET_SUBSCRIPTIONS_LIST';
export const SHOW_PAYMENTS_MODAL = 'SHOW_PAYMENTS_MODAL';
