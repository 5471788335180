import { SET_CRS_POINTS, SET_CRS_POINTS_FETCH_TIME } from "../Actions/actionTypes";

const initialState = {
  time: null,
  data: null,
};

const resultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CRS_POINTS:
      return {
        ...state,
        data: action.payload,
      };
    case SET_CRS_POINTS_FETCH_TIME:
      return {
        ...state,
        time: action.payload
      };
    default:
      return state;
  }
};

export default resultsReducer;
