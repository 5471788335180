import { SHOW_PAYMENTS_MODAL } from "../Actions/actionTypes";

const initialState = {
  paymentsModal: {
    show: false,
  },
};

const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PAYMENTS_MODAL:
      return {
        ...state,
        paymentsModal: {
          show: action.payload,
        },
      };
    default:
      return state;
  }
};

export default paymentsReducer;
